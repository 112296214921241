<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/booking">
        {{ $t("reservationAndUsageHistory.reservationAndUsageHistory") }}
      </router-link>
      >
      <router-link
        :to="{
          path: '/home/booking/reservation-details/' + id + '/',
          params: { id }
        }"
      >
        {{ $t("reservationAndUsageHistory.reservationDetails") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>

    <div class="form-container pb-8">
      <UsageHistoryItem
        :id="booking.id"
        :urlImg="facility.mainImageUrl"
        :title="facility.name"
        :checkInDate="booking.checkInDate"
        :checkOutDate="booking.checkOutDate"
        :roomTypeName="booking.room.roomType.name"
        isActive
        :cancelledAt="cancelledAt"
      />

      <div class="bg-w-silver">
        <div class="bg-orange--grad">
          <div class="text-title --white text-center font-weight-bold pt-3 pb-3">
            {{ $t("reservationAndUsageHistory.changeReservationDetails") }}
          </div>
        </div>
        <div class="px-5 pb-4">
          <div class="text-medium text-center  --c-bg text-left pt-3 pb-3">
            <span v-if="!canModify">{{ $t('text.thisReservationCannotBeChanged') }}</span>
            <span v-else>{{ $t("reservationAndUsageHistory.pleaseSelectTheContentToBeChangedFromTheFollowing") }}</span>
          </div>

          <v-btn
            elevation="0"
            rounded
            block
            text
            x-large
            v-for="(btn, ind) in btns"
            :key="`btn-${ind}`"
            @click="btn.click"
            class="text-medium mt-3 mb-3"
            :class="btn.class || 'btn-grad--prm'"
            :color="btn.color || '--white'"
            :disabled="btn.disabled"
          >
            {{ btn.text }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'
import UsageHistoryItem from '@/components/UsageHistoryItem'
import selectedBookingWithParkingAndRentalMixin from '../../selectedBookingWithParkingAndRentalMixin'

export default {
  name: 'ReservationChange',
  mixins: [selectedBookingWithParkingAndRentalMixin],
  components: {
    Breadcrumbs,
    UsageHistoryItem
  },
  methods: {
    handleClick (link) {
      if (link) {
        this.$router.push(link)
      } else {
        this.reservationCancellation.isOpen = true
      }
    }
  },
  data () {
    return {
      reservationCancellation: {
        isOpen: true
      }
    }
  },
  computed: {
    btns () {
      const buttons = []
      if (this.canModify) {
        buttons.push({
          text: this.$t('reservationAndUsageHistory.changeGuestInformationChange'),
          click: async () => {
            await this.$router.push({
              name: 'home-booking-reservation-details-detail-reservation-change-guest-information',
              params: { id: this.id.toString() }
            })
          }
        })
        buttons.push({
          text: this.$t('reservationAndUsageHistory.changeRentalListingChange'),
          click: async () => {
            await this.$router.push({
              name: 'home-booking-reservation-details-detail-reservation-change-rental-listing-change',
              params: { id: this.id.toString() }
            })
          },
          disabled: !this.hasRentalItems || !this.canBookRentalItem
        })
        buttons.push({
          text: this.$t('reservationAndUsageHistory.changeParkingLotChange'),
          click: async () => {
            await this.$router.push({
              name: 'home-booking-reservation-details-detail-reservation-change-parking-change',
              params: { id: this.id.toString() }
            })
          },
          disabled: !this.hasParkings || !this.canBookParking
        })

        if (this.acceptsPet && !this.showOldPetInfo) {
          // TO2020-653: change pet
          buttons.push({
            text: this.$t('reservationAndUsageHistory.changePetInfoChange'),
            click: () => {
              this.$router.push({
                name: 'home-booking-reservation-details-detail-reservation-pet-change',
                params: { id: this.id.toString() }
              })
            }
          })
        }

        if (this.canUseTvp) {
          buttons.push({
            text: this.$t('reservationAndUsageHistory.changeTVPPrePaymentChange'),
            click: () => {
              this.$router.push({ name: 'reservation-tvp-prepayment-change', params: { id: this.id.toString() } })
            },
            disabled: !this.canModify
          })
        }
      }
      buttons.push({
        text: this.$t('buttons.return'),
        class: 'bg-white',
        color: '--prm',
        click: async () => {
          await this.$router.replace({
            name: 'home-booking-reservation-details-detail',
            params: { id: this.id.toString() }
          })
        }
      })

      return buttons
    }
  }
}
</script>
